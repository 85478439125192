import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import {
  useIsFetching,
  useIsMutating,
  useQueryClient,
} from '@tanstack/react-query'
import {
  Anchor,
  Button,
  GridContainer,
  GridItem,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { useCallback } from 'react'
import { trackLogOut } from '../../common/fireflyEvents'
import {
  getUserSpecificConfig,
  removeLocalStorageItems,
} from '../../common/helper'
import { SIGN_OUT } from '../../constants'
import { StyledForbiddenModal } from './styles'

const ForbiddenModal = () => {
  const queryClient = useQueryClient()
  const auth = useAuth()
  const apiConfig = getUserSpecificConfig(useEnv())
  const isLoading = useIsMutating() + useIsFetching()

  const signOut = useCallback(() => {
    trackLogOut()
    removeLocalStorageItems(['selected_vendor', 'external_vendor_detail'])
    auth.logout()
    queryClient.clear()
  }, [auth, queryClient])

  return (
    <>
      {' '}
      {!isLoading && (
        <StyledForbiddenModal>
          <Modal headingText="Data not available" isVisible>
            <div className="hc-pa-normal hc-pa-lg  hc-fs-md">
              <div>
                There is no Roundel campaign data associated to this account.
                Note that Roundel Target Product Ads, Programmatic by Roundel,
                and Multi-Vendor campaigns are not currently supported.
              </div>
              <br />
              <div>
                If you feel like you are seeing no data in error{' '}
                <Anchor href={apiConfig.supportURL} target="_blank">
                  please submit a ticket here.
                </Anchor>
              </div>
              <br />
              <div>
                To learn more about running digital media campaigns with Roundel
                reach out to your Roundel account manager or learn more at{' '}
                <Anchor href="https://roundel.com/" target="_blank">
                  www.Roundel.com
                </Anchor>
              </div>

              <GridContainer direction="row-reverse" spacing="dense">
                <GridItem>
                  <Button
                    type="primary"
                    data-test="sign-out"
                    onClick={() => {
                      signOut()
                    }}
                  >
                    {SIGN_OUT}
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
          </Modal>
        </StyledForbiddenModal>
      )}
    </>
  )
}

export default ForbiddenModal
