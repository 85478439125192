import {
  Anchor,
  GridContainer,
  Heading,
  Button,
  GridItem,
} from '@enterprise-ui/canvas-ui-react'
import getApiConfig from '../../config/apiConfig'
import { DASHBOARD_PATH } from '../../constants'
import {
  POL_DEV_SUPPORT_LINK,
  POL_PROD_SUPPORT_LINK,
} from '../../config/appConfig'
import { isLoggedInAsInternalUser } from '../../common/helper'
import { StyledDashboardBodyLayout } from './styles'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import logo from '../../assets/Roundel_Media_Logo_White.png'
import { useEffect, useState } from 'react'
import Header from '../Header'
/**
 * Component shown when component encountered errors
 */

interface Props {
  userDetails: Record<string, any>
}

const FallbackScreen = (props: Props) => {
  const { userDetails } = props
  const [apiConfig, setApiConfigState] = useState<ApiConfig>({
    appEnv: 'dev',
  })

  useEffect(() => {
    const setApiConfig = async () => {
      const apiConfig: ApiConfig = await getApiConfig()
      setApiConfigState(apiConfig)
    }
    setApiConfig()
  })

  const supportUrl =
    apiConfig.appEnv === 'prod' ? POL_PROD_SUPPORT_LINK : POL_DEV_SUPPORT_LINK

  return (
    <Layout fullWidth={true} theme="roundel" darkMode={false}>
      <Header userDetails={userDetails} />
      <StyledDashboardBodyLayout includeRail={true} className="hc-pa-none">
        <div className="hc-pa-normal hc-pa-2x hc-mt-3x Roundel__Gradient--vertical-blue-purple hc-full-height-error">
          <GridContainer className="hc-pa-none">
            <GridItem xs={12}>
              <div className="wrapper">
                <Heading className="messageHeader">
                  Something went wrong!
                </Heading>
                {isLoggedInAsInternalUser() ? (
                  <Heading className="hc-fs-md">
                    <span>Please slack Kiosk team at for help.</span>
                  </Heading>
                ) : (
                  <Heading className="hc-fs-md">
                    <span>Please submit the support ticket at for help.</span>
                  </Heading>
                )}
                <Heading className="buttonContainer">
                  {isLoggedInAsInternalUser() ? (
                    <Button
                      className="hc-mr-sm buttonSupport"
                      data-test="create-new-metric-btn"
                      type="secondary"
                    >
                      <Anchor
                        href="https://target.slack.com/archives/C02TUCVQNDN"
                        target="_blank"
                        data-test="slackLink"
                      >
                        #roundel-kiosk-support
                      </Anchor>
                    </Button>
                  ) : (
                    <Button
                      className="hc-mr-sm buttonSupport"
                      data-test="create-new-metric-btn"
                      type="secondary"
                    >
                      <Anchor
                        href={supportUrl}
                        target="_blank"
                        data-test="POLSupportLink"
                      >
                        POL Support
                      </Anchor>
                    </Button>
                  )}
                  <Button
                    className="hc-mt-sm buttonLink"
                    data-test="create-new-metric-btn"
                    type="primary"
                  >
                    <Anchor href={DASHBOARD_PATH}>Back to Dashboard</Anchor>
                  </Button>
                </Heading>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </StyledDashboardBodyLayout>
    </Layout>
  )
}

export default FallbackScreen
